import Vue from "vue";
import Vuex from "vuex";
import VuexORM from "@vuex-orm/core";

import { setTelemetryUser } from "@socotec.io/socio-vue-components";
// Vuex OIDC
import { vuexOidcCreateStoreModule } from "vuex-oidc";
import { oidcSettings } from "@/setup/OIDCSettings";

// Client
import { initTestRestAuth } from "@/setup/axios";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { initDocumentAuth } from "@/setup/restDocumentClient";
import { stonlyIdentifyWidget } from "@/api/stonlyIdentifyWidget";

/************************************ Models ******************************************/
import SspCase from "@/models/Case";
import Mission from "@/models/Mission";
import Survey from "@/models/Survey";
import Sample from "@/models/Sample";
import Preparation from "@/models/Preparation";
import Analysis from "@/models/Analysis";
import Material from "@/models/Materials";
import PreparationMaterialValidated from "@/models/PreparationMaterialValidated";
import PreparationMaterial from "@/models/PreparationMaterial";
import AdditionalMaterial from "@/models/AdditionalMaterial";
import ElementaryMission from "@/models/ElementaryMission";
import GlobalMission from "@/models/GlobalMission";
import Tag from "@/models/Tag";
import AdditionalInformation from "@/models/AdditionalInformation";
import FloorInformation from "@/models/investegatedEnvs/FloorInformation";
import UnderGroundWaterInformation from "@/models/investegatedEnvs/UnderGroundWaterInformation";
import SoilGazInformation from "@/models/investegatedEnvs/SoilGazInformation";
import ExcavatedGroundsInformation from "../models/investegatedEnvs/ExcavatedGroundsInformation";
import AmbientAirInformation from "@/models/investegatedEnvs/AmbientAirInformation";
import Dashboard from "@/models/Dashboard";
import Parcel from "@/models/Parcel";
import AnalysisRequest from "@/models/AnalysisRequest";
import PurgeSoilGas from "@/models/Purge/PurgeSoilGas";
import PurgeUndergroundWaters from "@/models/Purge/PurgeUndergroundWaters";
import PurgeSoilGasMeasure from "@/models/Purge/PurgeSoilGasMeasure";
import PurgeUndergroundWatersMeasure from "@/models/Purge/PurgeUndergroundWatersMeasure";
import CalibrationsVerification from "@/models/CalibrationsVerification";
import MissionArchive from "@/models/MissionArchive";

import {
  Contributor,
  User,
  Agency,
  Role,
  Company,
  CompanyProjectRole,
  AgencyGroup,
  Client,
  Project,
  Case,
  Material as BaseMaterial,
  ReportTemplate,
  Report as BaseReport,
  Document,
} from "@socotec.io/socio-vue-components";

// Site visit Questionnaire
import Activity from "@/models/SiteVisit/Activity.js";
import Building from "@/models/SiteVisit/Building.js";
import Incident from "@/models/SiteVisit/Incident.js";
import Landfill from "@/models/SiteVisit/Landfill.js";
import Location from "@/models/SiteVisit/Location.js";
import PersonMet from "@/models/SiteVisit/PersonMet.js";
import Questionnaire from "@/models/SiteVisit/Questionnaire.js";
import Storage from "@/models/SiteVisit/Storage.js";
import SuperStructure from "@/models/SiteVisit/SuperStructure.js";
import Advice from "@/models/SiteVisit/Advice.js";
import IdentifiedSource from "@/models/SiteVisit/IdentifiedSource.js";
import projectInformation from "@/models/ProjectInformation";
import Attachments from "@/models/Attachments";
import AdditionalPlans from "@/models/AdditionalPlans";
import Section from "@/models/Section";
import Cadaster from "@/models/Cadaster";
import SiteInformation from "@/models/SiteInformation";
// Report questionnaire
import ReportQuestionnaire from "@/models/Report/Questionnaire";
import ProvisionalProgram from "@/models/Report/ProvisionalProgram";
import ConceptualDiagram from "@/models/Report/ConceptualDiagram";
import Investigation from "@/models/Report/Investigation";
import FoodLaboratoryAnalysis from "@/models/Report/FoodLaboratoryAnalysis";
import SoilGasLaboratoryAnalysis from "@/models/Report/SoilGasLaboratoryAnalysis";
import SoilGasInvestigation from "@/models/Report/SoilGasInvestigation";
import SamplingTimeAndFlowRate from "@/models/Report/SamplingTimeAndFlowRate";
import Weather from "@/models/Report/Weather";
import SampleEnvironment from "@/models/Report/SampleEnvironment";
import SoilAnalysisProgram from "@/models/Report/SoilAnalysisProgram";
import UndergroundWaterAnalyticalProgram from "@/models/Report/UndergroundWaterAnalyticalProgram";
import UndergroundWaterInvestigationsStructure from "@/models/Report/UndergroundWaterInvestigationsStructure";
import ExcavatedGroundAnalysisProgram from "@/models/Report/ExcavatedGroundAnalysisProgram";
import WaterPhysicoChemicalComposition from "@/models/Report/WaterPhysicoChemicalComposition";
import PiezometricLevel from "@/models/Report/PiezometricLevel";
import AmbientAirAnalysisProgram from "@/models/Report/AmbientAirAnalysisProgram";
import AmbientAirSamplingJustification from "@/models/Report/AmbientAirSamplingJustification";
import PhotographsPlansAnalysis from "@/models/Report/PhotographsPlansAnalysis";
import ActivitiesAndProcessesHistory from "@/models/Report/ActivitiesAndProcessesHistory";
import AdministrativeSituationsHistory from "@/models/Report/AdministrativeSituationsHistory";
import UsedProductAndWaste from "@/models/Report/UsedProductAndWaste";
import ProductUsedAndRawMaterial from "@/models/Report/ProductUsedAndRawMaterial";
import RiskyActivity from "@/models/Report/RiskyActivity";
import SiteSourceOfContamination from "@/models/Report/SiteSourceOfContamination";
import GeologicalWorkNearSite from "@/models/Report/GeologicalWorkNearSite";
import IdentifiedGroundwaterCatchment from "@/models/Report/IdentifiedGroundwaterCatchment";
import MonitoringStructureNearSite from "@/models/Report/MonitoringStructureNearSite";
import SuperficialWaterLaboratoryAnalysis from "@/models/Report/SuperficialWaterLaboratoryAnalysis";
import BasiasBasolSite from "@/models/Report/BasiasBasolSite";
import Site from "@/models/Site";
import ImmediateDanger from "@/models/ImmediateDanger";
import NeighboringUse from "@/models/NeighboringUse";
import NeighboringUseElement from "@/models/NeighboringUseElement";
import CartographyElement from "@/models/CartographyElement";
import OutdoorSpace from "@/models/OutdoorSpace";
import PollutedEnvironment from "@/models/PollutedEnvironment";
import PollutedExtraEnvironment from "@/models/PollutedExtraEnvironment";
import Complaint from "@/models/Complaint";
// Reports list
import SspReport from "@/models/SspReport";

// Contact form and visit
import ContactForm from "@/models/ContactForm/ContactForm";
import SiteActivity from "@/models/ContactForm/SiteActivity";
import SiteBuilding from "@/models/ContactForm/SiteBuilding";

// Cartogy / AOS

import AosSite from "@/models/cartography/AosSite";
import AosBuilding from "@/models/cartography/Building";
import Component from "@/models/cartography/Component";
import Marker from "@/models/cartography/Marker";
import OtherItem from "@/models/cartography/OtherItem";
import Zone from "@/models/cartography/Zone";
import AosOutdoorSpace from "@/models/cartography/AosOutdoorSpace";
import Polygon from "@/models/cartography/Polygon";
import AosPicture from "@/models/cartography/AosPicture";

// Testimony

import Testimony from "@/models/testimonials/Testimony";
import Pollution from "@/models/testimonials/Pollution";
import Product from "@/models/testimonials/Product";
import ICPE from "@/models/testimonials/ICPE";
import Restriction from "@/models/testimonials/Restriction";
import Accident from "@/models/testimonials/Accident";
import ActivityTestimonial from "@/models/testimonials/Activity";

// Vulnerability

import VulnerabilityInformation from "@/models/vulnerability/VulnerabilityInformation";
import VulnerabilityUsage from "@/models/vulnerability/VulnerabilityUsage";
import GeologicalFormation from "@/models/vulnerability/GeologicalFormation";
import NeighbourPlu from "@/models/vulnerability/NeighbourPlu";
import BssWork from "@/models/vulnerability/BssWork";
import LithologyInformation from "@/models/vulnerability/LithologyInformation";

// OQC

// historical research
import HistoricalResearch from "@/models/historicalResearch/HistoricalResearch";
import LithologicalLayer from "@/models/LithologicalLayer";
import ComplementaryAnalysis from "@/models/ComplementaryAnalysis";
import ComplementaryAnalysisRequest from "@/models/ComplementaryAnalysisRequest";

/************************************ Modules ******************************************/

import { notifications as NotificationModule } from "@socotec.io/socio-vue-components";
import UserModule from "./modules/user";
import RoleModule from "./modules/roles";
import ContributorModule from "./modules/contributor";
import CompanyModule from "./modules/company";
import CaseModule from "./modules/case";
import ElementaryMissionModule from "./modules/elementarymission";
import GlobalMissionModule from "./modules/globalmission";
import MissionModule from "./modules/mission";
import SurveyModule from "./modules/survey";
import SampleModule from "./modules/sample";
import SiteVisitModule from "./modules/sitevisit";
import SiteModule from "./modules/site";
import ImmediateDangerModule from "./modules/immediate-danger";
import SiteVisitRelatedModule from "./modules/sitevisit-related";
import ReportModule from "./modules/report";
import ReportChaptersModule from "./modules/report-chapters";
import ReportRelatedModule from "./modules/report-related";
import ReportsListModule from "./modules/reportslist";
import MaterialModule from "@/store/modules/material";
import AdditionalsMaterialModule from "@/store/modules/additionalsMaterial";
import PreparationModule from "./modules/preparation";
import AnalysisModule from "./modules/analysis";
import AnalysisRequestModule from "./modules/analysis-request";
import ClientModule from "./modules/client";
import DocumentModule from "./modules/document";
import AdditionalInformationModule from "./modules/additional-information";
import FloorInformationModule from "./modules/floor-information";
import UnderGroundWaterInformationModule from "./modules/under-ground-water-information";
import SoilGazInformationModule from "./modules/soil-gaz-information";
import ExcavatedGroundsInformationModule from "./modules/excavated-grounds-information";
import AmbientAirInformationModule from "./modules/ambient-air-information";
import ContactModule from "./modules/contact";
import ParcelModule from "./modules/parcel";
import PurgeModule from "./modules/purge";
import LithologicalLayerModule from "./modules/lithology";
import CalibrationsVerificationModule from "@/store/modules/calibrations-verification";
import ComplementaryAnalysisModule from "./modules/complementaryAnalysis";
import MissionArchiveModule from "@/store/modules/mission-archive";
import NeighboringUseModule from "./modules/neighboringUse";
import ProjectInfoModule from "./modules/project-information";
import SectionModule from "./modules/section";
import EnvironmentModule from "./modules/environment";
import SiteInformationModule from "./modules/site-information";
import CartographyModule from "@/store/modules/cartography";
import TestimonialsModule from "@/store/modules/testimonials";
import TechnicalOffersModule from "@/store/modules/technical-offers";
import HistoricalResearchModule from "@/store/modules/historical-research";
import vulnerabilityModule from "./modules/vulnerability";
/*************************************************************************************** */

Vue.use(Vuex);

const database = new VuexORM.Database();
database.register(Contributor);
database.register(CompanyProjectRole);
database.register(Client);
database.register(Case);
database.register(SspCase);
database.register(Project);
database.register(Mission);
database.register(User);
database.register(Agency);
database.register(AgencyGroup);
database.register(Role);
database.register(Company);
database.register(ReportQuestionnaire);
database.register(ProvisionalProgram);
database.register(ConceptualDiagram);
database.register(Investigation);
database.register(ElementaryMission);
database.register(GlobalMission);
database.register(PreparationMaterial);
database.register(PreparationMaterialValidated);
database.register(BaseMaterial); // socio-vue-component
database.register(Material); // ssp-front
database.register(Document);
database.register(Tag);
database.register(Dashboard);
database.register(Parcel);
database.register(MissionArchive);
database.register(Site);
database.register(ImmediateDanger);
database.register(NeighboringUse);
database.register(NeighboringUseElement);
database.register(CartographyElement);
database.register(Attachments);
database.register(AdditionalPlans);
database.register(projectInformation);
database.register(Section);
database.register(OutdoorSpace);
database.register(PollutedEnvironment);
database.register(PollutedExtraEnvironment);
database.register(Complaint);
database.register(Cadaster);
database.register(SiteInformation);
// Intervention
database.register(AdditionalMaterial); // ssp-front
database.register(Survey);
database.register(Sample);
database.register(Analysis);
database.register(AnalysisRequest);
database.register(Preparation);
database.register(AdditionalInformation);
database.register(FloorInformation);
database.register(UnderGroundWaterInformation);
database.register(SoilGazInformation);
database.register(ExcavatedGroundsInformation);
database.register(AmbientAirInformation);
database.register(PurgeSoilGas);
database.register(PurgeUndergroundWaters);
database.register(PurgeSoilGasMeasure);
database.register(PurgeUndergroundWatersMeasure);
database.register(LithologicalLayer);
database.register(CalibrationsVerification);
database.register(ComplementaryAnalysis);
database.register(ComplementaryAnalysisRequest);

// Report
database.register(ReportQuestionnaire);
database.register(FoodLaboratoryAnalysis);
database.register(SoilGasLaboratoryAnalysis);
database.register(SoilGasInvestigation);
database.register(SamplingTimeAndFlowRate);
database.register(Weather);
database.register(SampleEnvironment);
database.register(SoilAnalysisProgram);
database.register(UndergroundWaterAnalyticalProgram);
database.register(UndergroundWaterInvestigationsStructure);
database.register(ExcavatedGroundAnalysisProgram);
database.register(PiezometricLevel);
database.register(WaterPhysicoChemicalComposition);
database.register(AmbientAirAnalysisProgram);
database.register(AmbientAirSamplingJustification);
database.register(PhotographsPlansAnalysis);
database.register(ActivitiesAndProcessesHistory);
database.register(AdministrativeSituationsHistory);
database.register(UsedProductAndWaste);
database.register(ProductUsedAndRawMaterial);
database.register(RiskyActivity);
database.register(SiteSourceOfContamination);
database.register(GeologicalWorkNearSite);
database.register(IdentifiedGroundwaterCatchment);
database.register(MonitoringStructureNearSite);
database.register(SuperficialWaterLaboratoryAnalysis);
database.register(BasiasBasolSite);

// Site visit
database.register(Activity);
database.register(Building);
database.register(Incident);
database.register(Landfill);
database.register(Location);
database.register(PersonMet);
database.register(Questionnaire);
database.register(Storage);
database.register(SuperStructure);
database.register(Advice);
database.register(IdentifiedSource);

// Reports list
database.register(ReportTemplate); // socio-vue-component
database.register(BaseReport); // socio-vue-component
database.register(SspReport);

// Contact form and visit
database.register(ContactForm);
database.register(SiteActivity);
database.register(SiteBuilding);

// Cartography / AOS
database.register(Marker);
database.register(AosSite);
database.register(Component);
database.register(OtherItem);
database.register(Zone);
database.register(AosOutdoorSpace);
database.register(AosBuilding);
database.register(Polygon);
database.register(AosPicture);

// Testimony
database.register(Testimony);
database.register(Pollution);
database.register(Product);
database.register(ICPE);
database.register(Restriction);
database.register(Accident);
database.register(ActivityTestimonial);
// Historical research
database.register(HistoricalResearch);

// Vulnerability
database.register(VulnerabilityInformation);
database.register(VulnerabilityUsage);
database.register(GeologicalFormation);
database.register(NeighbourPlu);
database.register(BssWork);
database.register(LithologyInformation);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    oidc: vuexOidcCreateStoreModule(
      oidcSettings,
      {
        namespaced: true,
        dispatchEventsOnWindow: true,
      },
      {
        userLoaded: (user) => {
          initTestRestAuth(user.access_token);
          initDocumentAuth(user.access_token);
          socioGrpcClient.setAccessToken(user.access_token);
          setTelemetryUser(user);
          stonlyIdentifyWidget(user.profile.email, user.profile.name);
        },
        userUnloaded: () => console.log("OIDC user is unloaded"),
        accessTokenExpiring: () => console.log("Access token will expire"),
        accessTokenExpired: () => console.log("Access token did expire"),
        silentRenewError: () => console.log("OIDC user is unloaded"),
        automaticSilentRenewError: (payload) =>
          console.log("Automatic silent renew failed.", payload.error),
        userSignedOut: () => console.log("OIDC user is signed out"),
        oidcError: (payload) =>
          console.error(
            `An error occured at ${payload.context}:`,
            payload.error
          ),
      }
    ),
    notifications: NotificationModule,
    user: UserModule,
    roles: RoleModule,
    contributors: ContributorModule,
    companies: CompanyModule,
    cases: CaseModule,
    missions: MissionModule,
    surveys: SurveyModule,
    samples: SampleModule,
    sitevisit: SiteVisitModule,
    sitevisitRelated: SiteVisitRelatedModule,
    report: ReportModule,
    reportChapters: ReportChaptersModule,
    reportRelated: ReportRelatedModule,
    elementaryMission: ElementaryMissionModule,
    globalMission: GlobalMissionModule,
    materials: MaterialModule,
    additionalsMaterial: AdditionalsMaterialModule,
    preparation: PreparationModule,
    analysis: AnalysisModule,
    analysisRequest: AnalysisRequestModule,
    clients: ClientModule,
    reportslist: ReportsListModule,
    document: DocumentModule,
    additionalInformation: AdditionalInformationModule,
    floorInformation: FloorInformationModule,
    underGroundWaterInformation: UnderGroundWaterInformationModule,
    soilGazInformation: SoilGazInformationModule,
    excavatedGroundsInformation: ExcavatedGroundsInformationModule,
    ambientAirInformation: AmbientAirInformationModule,
    missionArchive: MissionArchiveModule,
    contacts: ContactModule,
    parcels: ParcelModule,
    purges: PurgeModule,
    lithologicalLayers: LithologicalLayerModule,
    calibrationsVerification: CalibrationsVerificationModule,
    complementaryAnalysis: ComplementaryAnalysisModule,
    site: SiteModule,
    immediateDanger: ImmediateDangerModule,
    neighboringUse: NeighboringUseModule,
    cartography: CartographyModule,
    projectInformation: ProjectInfoModule,
    section: SectionModule,
    environment: EnvironmentModule,
    siteInformation: SiteInformationModule,
    testimonials: TestimonialsModule,
    technicalOffers: TechnicalOffersModule,
    historicalResearch: HistoricalResearchModule,
    vulnerability: vulnerabilityModule,
  },
  plugins: [VuexORM.install(database)],
});
